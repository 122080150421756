<template>
   <div class="inter overflow">
      <div class="mask" v-if="isMaskVisible">
          <!-- 加载动画start -->
          <loadAction :loading="isLoad" />
          <!-- 加载动画end --> 
      </div>       
      <tablelist
          :tableArray="rechangeData"
          :defaultTable="defaultTable"
          @currentMethod="currentMethod"
          @onRowClick="onRowClick"
          :pageSize="pageSize"
          :isPage="true"
          :total="total">
      </tablelist>
      <el-dialog :visible.sync="isShowGroupInfo" width="330px" custom-class="groupDialog" :show-close="false" destroy-on-close  top="20vh">
        <div class="groupContent">
            <div class="codeText overflow">
                <div class="codePic codeImg flex" :class="{'flexCenter codeActive':!groupInfo}">
                    <div class="codeShow" v-if="groupInfo">
                        <img :src="groupInfo.group_qr_code" alt="" style="width: 100%;">
                        <div class="codeShowText">
                            <p>类型：{{groupInfo.group_type_desc}}<span
                                    class="codeShowTextMargin"> 人数：{{groupInfo.number_of_people?groupInfo.number_of_people:0}}人</span>
                            </p>
                            <p>名称：{{groupInfo.name}}</p>
                            <p>采集时间：{{groupInfo.create_time}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" style=" text-align:center !important">
          <el-button type="primary" @click="isShowGroupInfo = false">关闭</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  <script>
  import api from "../../http/api";
  import { Message } from 'element-ui';
  export default {
    data() {
      return {
        isMaskVisible:true,
        isLoad: true,  //加载动画
        isShowGroupInfo: false,
        groupInfo:{
            group_qr_code:'',
            group_type_desc:'',
            number_of_people:1,
            name:'',
            create_time:''
        },
        rechangeData: [
          {
            key: "group_type_desc",
            name: "类型",
            width: "8%",
          },
          {
            key: "group_name",
            name: "群名称",
            width: "32%",
          },
          {
            key: "create_time",
            name: "采集时间",
            width: "17%",
          },
          {
            key: "need_points",
            name: "消耗积分",
            width: "8%",
          },
          {
            key: "is_download",
            name: "是否下载",
            width: "7%",
          },
          {
            key: "download_time",
            name: "下载时间",
            width: "14%",
          },
          {
              name: "操作",
              width: "8%",
              formatter:(row) => {
                  return row.can_view? <el-link type='primary' style='color:blue;cursor:pointer'>再次查看</el-link> : <el-link type='primary' style='color:#666;cursor:pointer'>再次查看</el-link>
              }
          }
        ], //表格头部
        defaultTable: [],
        total: 0, //总页数
        pageSize: 40, //分页
        pageIndex: 1, //当前页码
      };
    },
    components: {
      loadAction: () => import("../components/load"),
      tablelist: () => import("../components/table"),
    },
    created() {

    },
    activated() {
      this.isLoad =true
      this.isMaskVisible = true
      this.getList();
    },
    methods: {
      onRowClick(row, column) {
          if(column.label==='操作') {
            if(row.can_view) {
                this.isShowGroupInfo = true
                this.groupInfo= {
                    group_qr_code:row.group_qr_code,
                    group_type_desc:row.group_type_desc,
                    number_of_people:row.number_of_people,
                    name:row.group_name,
                    create_time:row.create_time
                }
            }
            else{
              Message({
                message: `3天前的群二维码可能大部分已过期，不支持再次查看！`,
                type: 'warning'
              });
            }
          }
      },
      getDaysBetweenDays(startDate, endDate) {
        var timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return diffDays;
      },
      currentMethod(index) {
        this.getList(index);
      },
      async getList(data) {
        let that = this;
        that.pageIndex = data ? data : that.pageIndex;
        let result = await api.getDetail({
          page_size: that.pageSize,
          page_index: that.pageIndex, //当前页码
        });
        if (result.code == 200) {
          that.total = result.response.total;
          that.defaultTable =
            result.response.data && result.response.data.length != 0
              ? result.response.data.map((item) => {
                  item.is_download = item.is_download ? "是" : "否";
                  item.can_view= that.getDaysBetweenDays(new Date(item.create_time),new Date())<=3
                  return { ...item };
                })
              : [];
              setTimeout(() => {
                this.isMaskVisible = false;
                this.isLoad = false;
              }, 200);
          }
          else {
            setTimeout(() => {
                this.isMaskVisible = false;
                this.isLoad = false;
              }, 200);
          }
      }
    }
  };
  </script>
  <style lang="less" scoped>
  .inter {
    width: 100%;
    height: 100%;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.98); /* 半透明黑色背景 */
    z-index: 1000; /* 确保它在其他元素之上 */
   }

   /deep/ .el-dialog__footer {
        text-align: center;
    }
    /deep/ .el-dialog__body{
      padding-bottom: 20px;
    }

    /deep/ .el-dialog__header{
      padding:10px;
    }
   .groupContent {
      width: 100%;
      height: 100%;
      .codeShow{
        width: 100%;
      }

      .codeImg {
            width: 100%;
            height: 100%;

            .codeShow {
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    display: block;
                }

                .codeShowText {
                    height: calc(100% - 280px);
                    overflow-y: auto;
                    padding: 0px 10px;

                    p {
                       margin: 5px 0;
                       font-size: 15px;
                    }

                    .codeShowTextMargin {
                        margin-left: 20px;
                    }
                }
            }

        }

      .collectCode {
          width: 100%;
          border: 1px solid #DCDCDC;
          height: 100%;
      }

      .collectCodeTitle {
          background: #DCDCDC;
          line-height: 30px;
          width: 100%;
      }
      .codeText {
          width: 100%;
          height: calc(100% - 30px);
          align-items: flex-start;
      }

      .codeActive {
          align-items: center !important;
      }
    }
  </style>
  